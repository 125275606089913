import React, { useState } from "react";
import {
  MDBCardBody,
  MDBInput,
  MDBBtn,
  MDBCardTitle,
  MDBCardHeader,
  MDBContainer,
  MDBCard,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBIcon,
  MDBSpinner,
} from "mdb-react-ui-kit";
import axios from "axios";

const Form = () => {
  const [form, setForm] = useState({});
  const [studDetails, setStudDetails] = useState([]);
  const [studModal, setStudModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const testDelete = () => {
    const token =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJvZ3VudHVuZGViQHJ1bi5lZHUubmciLCJleHAiOjE3MzY0NDUzMjh9.OYAbaJ5urUcjdbd9sRHWiodjZaBNFH4eggNj_5Q6O9Y";
    const mat_no = "123456";
    axios
      .post(
        `http://acc.run.edu.ng/api/ug/admin/test_delete_student_from_room_in_session?mat_no=${mat_no}`,
        {},
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        alert(res);
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          alert(err.response.data.message);
        } else {
          alert("Network Error!");
        }
      });
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setForm((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleCheck = () => {
    if (Object.keys(form).length === 0) return false;
    setLoading(true);
    axios
      .get(
        `https://reg.run.edu.ng/apis/profile/getstudent?matric=${form.mat_no}`,
        {
          params: { mat_no: form.mat_no, request_type: "get" },
        }
      )
      .then((res) => {
        setLoading(false);
        setStudDetails(res.data);
        console.log(res.data);
        setStudModal(!studModal);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        if (err.response) {
          alert(err.response.data);
        } else {
          alert("Network Error!");
        }
      });
  };

  const handleCheckIN = () => {
    setLoading(true);
    axios
      .post("https://hostel.run.edu.ng/api/get_student_profile", {
        mat_no: form.mat_no,
        request_type: "post",
      })
      .then((res) => {
        console.log(res);
        setLoading(false);
        alert(res.data.msg);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response) {
          alert(err.response.data.msg);
        } else {
          alert("Network Error!");
        }
      });
  };

  return (
    <>
      <MDBContainer breakpoint="md">
        <MDBCard alignment="center">
          <MDBCardHeader>RUN Gatepass</MDBCardHeader>
          <MDBCardBody>
            <MDBCardTitle>Enter Student's Matric Number Here</MDBCardTitle>
            <MDBInput
              id="mat_no"
              wrapperClass="mb-4"
              label="Matric Number"
              onChange={handleChange}
            />
            <MDBBtn type="submit" className="mb-4" block onClick={handleCheck}>
              {loading ? (
                <MDBSpinner role="status">
                  <span className="visually-hidden">Loading...</span>
                </MDBSpinner>
              ) : (
                "View Student"
              )}
            </MDBBtn>
            <MDBBtn type="submit" className="mb-4" block onClick={testDelete}>
              Test Delete
            </MDBBtn>
          </MDBCardBody>
        </MDBCard>
      </MDBContainer>

      <MDBModal show={studModal} setShow={setStudModal} tabIndex="-1">
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Student Details</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={() => setStudModal(false)}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <div>
                <MDBIcon icon="check-circle" className="me-2 text-success" />
                Name : {studDetails.surname + " " + studDetails.othernames}
                <hr></hr>
                <MDBIcon icon="check-circle" className="me-2 text-success" />
                Programme : {studDetails.program}
                <hr></hr>
                <MDBIcon icon="check-circle" className="me-2 text-success" />
                Level : {studDetails.level}
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={() => setStudModal(false)}>
                Close
              </MDBBtn>
              <MDBBtn color="success" onClick={handleCheckIN}>
                {loading ? (
                  <MDBSpinner role="status">
                    <span className="visually-hidden">Loading...</span>
                  </MDBSpinner>
                ) : (
                  "Check IN"
                )}
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
};
export default Form;
